<template>
  <div>
    <a-page-header
      :title="
        this.$route.query.look == 1
          ? '查看调研详情'
          : this.$route.query.id
          ? '修改调研信息'
          : '添加调研'
      "
      @back="() => $router.go(-1)"
    />

    <a-row>
      <a-col :span="16">
        <a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="标题">
            <a-input v-model="addFromData.title" />
          </a-form-item>
          <a-form-item label="简介">
            <a-textarea
              v-model="addFromData.desc"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
          <a-form-item label="问题">
            <a-select
              mode="multiple"
              label-in-value
              :value="value"
              placeholder="请选择调研问题(可搜索)"
              style="width: 100%"
              :filter-option="false"
              @search="fetchUser"
              @change="handleChangeSelect"
            >
              <a-select-option v-for="d in data" :key="d.id">
                {{ d.question_title }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="内容">
            <UE
              :sentContent="addFromData.content"
              @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="4">
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
          <a-form-item label="选择企业">
            <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="addFromData.company_name"
              :selectId="addFromData.company_id"
            ></CompanySelect>
          </a-form-item>
          <a-form-item label="开始时间">
            <a-date-picker
              tyle="width:160px"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              :locale="locale"
              v-model="addFromData.start_time"
            />
          </a-form-item>
          <a-form-item label="结束时间">
            <a-date-picker
              tyle="width:160px"
              format="YYYY-MM-DD"
              valueFormat="YYYY-MM-DD"
              :locale="locale"
              v-model="addFromData.end_time"
            />
          </a-form-item>

          <a-form-item label="排序">
            <a-input-number
              :min="0"
              v-model="addFromData.list_order"
              style="width:200px"
            />
          </a-form-item>
          <a-form-item label="状态">
            <a-radio-group v-model="addFromData.status">
              <a-radio :value="1">
                启用
              </a-radio>
              <a-radio :value="0">
                禁用
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="封面图">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>

          <a-button
            type="primary"
            style="margin-left:70px"
            @click="save"
            v-if="this.$route.query.look != '1'"
          >
            保存
          </a-button>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {
  surveyDataSave,
  uploadImage,
  surveyDetail,
  surveyQuestionList,
} from "@/apis/index";
import UE from "@/components/UE/UE.vue";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
export default {
  data() {
    return {
      locale,
      uploadImage,
      addFromData: {
        id: "",
        title: "",
        desc: "",
        content: "",
        img_id: "",
        status: 1,
        list_order: "",
        company_id: undefined,
        question_ids: "",
        company_name: "",
        start_time: "",
        end_time: "",
      },
      imageUrl: "",
      loading: false,
      //   下拉
      data: [],
      value: [],
    };
  },
  components: {
    UE,
  },
  watch: {
    value(val) {
      this.addFromData.question_ids = [];
      let arr = [];
      if (val.length > 0) {
        val.forEach((item) => {
          arr.push(item.key);
        });
        this.addFromData.question_ids = arr.toString();
      } else {
        this.addFromData.question_ids = "";
      }
    },
  },
  created() {
    this.getSurveyQuestionList();
    if (this.$route.query.id) {
      surveyDetail({ id: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.addFromData = { ...res.data.info };
          this.addFromData.company_name = res.data.info.company.company_name;
          res.data.question_list.forEach((item) => {
            this.value.push({
              key: item.id,
              label: item.question_title,
            });
          });
          this.addFromData.start_time =
            res.data.info.start_time == 0
              ? ""
              : moment(Number(res.data.info.start_time + "000")).format(
                  "YYYY-MM-DD"
                );
          this.addFromData.end_time =
            res.data.info.end_time == 0
              ? ""
              : moment(Number(res.data.info.end_time + "000")).format(
                  "YYYY-MM-DD"
                );
          this.imageUrl = res.data.info.img_url;
        }
      });
    }
  },

  methods: {
    save() {
      surveyDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.$router.push("/datamaintenances/Survey");
          this.$message.success("保存成功");
        }
      });
    },
    getSurveyQuestionList(keyword = "") {
      surveyQuestionList({ page: 1, limit: 50, keyword }).then((res) => {
        if (res.code == 1) {
          this.data = res.data.list;
        }
      });
    },

    fetchUser(value) {
      this.getSurveyQuestionList(value);
    },
    handleChangeSelect(value) {
      this.value = value;
    },
    CompanySelect(val) {
      this.addFromData.company_id = val;
    },
    getUeContent(val) {
      this.addFromData.content = val;
    },
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.img_id = info.file.response.data.id;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
